@tailwind base;
@tailwind components;
@tailwind utilities;

.fade-enter {
  opacity: 0;
  transform: scale(1.1);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}
